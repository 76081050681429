import React from "react"

import Check from "../../assets/images/Icons/check.svg"
import Link from "../../utils/link"

import "./ForgotPasswordConfirm.scss"

const ResetPasswordConfirm = () => {
  return (
    <div className="forgot-password-confirm">
      <img src={Check} className="forgot-password-confirm__icon" />

      <p className="forgot-password-confirm__title">
        Success! <br />
        <br />
        Your password has been reset.
      </p>

      <Link to="/" className="forgot-password-confirm__start">
        Back to Login
      </Link>

      <div className="forgot-password-confirm__email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
    </div>
  )
}

export default ResetPasswordConfirm
