import React from "react"

import AuthenticateFooter from "../components/LoginRegister/AuthenticateFooter"
import AuthenticateHeader from "../components/LoginRegister/AuthenticateHeader"
import ResetPasswordConfirm from "../components/LoginRegister/ResetPasswordConfirm"

import "../components/LoginRegister/Authenticate.scss"

const ForgotPasswordConfirmPage = () => {
  return (
    <div className="authenticate">
      <AuthenticateHeader />
      <div className="authenticate__main">
        <ResetPasswordConfirm />
      </div>
      <AuthenticateFooter />
    </div>
  )
}

export default ForgotPasswordConfirmPage
